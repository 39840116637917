import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import BSCard from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import ArticlePreview from '@/components/ArticlePreview/ArticlePreview'
import Card from '@/components/Card/Card'
import CardHover from '@/components/CardHover/CardHover'
import ContentPreview from '@/components/ContentPreview/ContentPreview'
import { ExplicitHead } from '@/components/DefaultHead/DefaultHead'
import Main from '@/components/Main/Main'
import requestService from '@/services/request.service'
import { ArticleType, DailyResponseType, WordpressResponseType } from '@/types/home'

import styles from './index.module.scss'

type Props = {
  enableAdhesion: boolean
  initialDaily: DailyResponseType
  initialWordpress: WordpressResponseType
}

const HomePage = ({ enableAdhesion, initialDaily, initialWordpress }: Props) => {
  const [dailyCommander] = useState(initialDaily)
  const [articles, setArticles] = useState(initialWordpress.articles)

  const [articlesPage, setArticlesPage] = useState(0)
  const [isLoadingMoreArticles, setIsLoadingMoreArticles] = useState(false)

  const fetchMoreArticles = async () => {
    setIsLoadingMoreArticles(true)
    const res = await fetch(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/wordpress/articles/${articlesPage + 1}`)
    const json = await res.json()
    setArticlesPage(articlesPage + 1)
    setArticles([...articles, ...json])
    setIsLoadingMoreArticles(false)
  }

  const shuffle = (array: string[]) => {
    let currentIndex = array.length

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      const randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }
  }

  const fetchDnsData = async (count: number) => {
    const requests = []

    for (let i = 0; i < count; i++) {
      const random_id = window.crypto.randomUUID()
      const url = `//${random_id}.dns-discovery-prod.spacecowmedia.com`

      const request = fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`)
          }
          return response.json() // Parse the response as JSON
        })
        .catch((err) => {
          console.error(`Request for ${random_id} failed:`, err)
          return null // Handle any request failure
        })

      requests.push(request)
    }

    // Wait for all requests and filter out failed ones
    const results = await Promise.all(requests)
    const successfulResults = results.filter((result) => result !== null)

    return successfulResults
  }

  const checkImageDomains = async () => {
    const imageSources = [
      'https://commandersherald.com/wp-content/uploads/2024/09/test-image-do-not-delete.png',
      'https://commandersheraldassets.com/wp-content/uploads/2024/09/test-image-do-not-delete.png',
      'https://www.commandersheraldassets.com/wp-content/uploads/2024/09/test-image-do-not-delete.png',
      'https://edhrec.com/articles/wp-content/uploads/2024/09/test-image-do-not-delete.png',
      'https://edhrecstatic.com/articles/wp-content/uploads/2024/09/test-image-do-not-delete.png',
      'https://cards.scryfall.io/small/front/6/9/695ccac5-c87e-49af-9131-8f1730e84bc0.jpg',
      'https://json.edhrec.com/test-image-do-not-delete.png',
      'https://json.edhrecstatic.com/test-image-do-not-delete.png',
      'https://edhrecstaticjson.com/test-image-do-not-delete.png',
    ]
    shuffle(imageSources)

    // A promise that resolves or rejects based on image loading status
    const checkImage = (src: string, retries = 2): Promise<null | { error: string; error2: string; src: string }> =>
      new Promise((resolve) => {
        const img = new Image()

        img.onload = () => resolve(null) // Success, resolve null for no failure

        img.onerror = (errorEvent) => {
          console.log('Error loading image:', src, errorEvent, 'Retries left:', retries)
          if (retries > 0) {
            // Retry with a reduced retry count after delay
            setTimeout(() => {
              resolve(checkImage(src, retries - 1))
            }, 500)
          } else {
            // Capture detailed error info after retries are exhausted
            resolve({
              // Stringified error objects
              error: JSON.stringify(errorEvent),
              error2: JSON.stringify(errorEvent, Object.getOwnPropertyNames(errorEvent)),
              // The image source
              src,
            })
          }
        }

        // Start loading the image
        img.src = src
      })

    // Check all images and gather results
    const results = await Promise.all(imageSources.map((src) => checkImage(src)))
    // Filter only the failed images that returned error objects
    const failed = results.filter((result) => result !== null)

    // If any images failed, send a POST request with the detailed errors
    if (failed.length > 0) {
      const dnsData = await fetchDnsData(4)

      fetch('/api/cloudflareerror/', {
        body: JSON.stringify({
          dns: dnsData,
          error: 'Failed to load images',
          fetchErrors: failed,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).catch((err) => {
        console.error('Failed to send error report:', err)
      })
    }
  }

  useEffect(() => {
    checkImageDomains()
  }, [])

  return (
    <Main>
      <div className={styles.container}>
        <ExplicitHead
          description='EDH Recommendations and strategy content for Magic: the Gathering Commander'
          keywords='EDHREC,EDH,recommendations,Commander,MTG,Magic,staples,strategy,content'
          title='EDHREC'
        />
        <div className={styles.containerLeft}>
          {articles
            .slice(0, -1)
            .reduce<ArticleType[][]>((res, _, i, arr) => {
              if (i % 2 === 0) res.push(arr.slice(i, i + 2))
              return res
            }, [])
            .map((articleRow) => (
              <div
                className={classNames(styles.grid, styles.row)}
                key={articleRow.map((article) => article.link).join()}
              >
                {articleRow.map((article) => (
                  <div className={styles.rowItem} key={article.link}>
                    {article.youtube ? <ContentPreview content={article} /> : <ArticlePreview article={article} />}
                  </div>
                ))}
              </div>
            ))}
          <div className={styles.row}>
            <span className={styles.loadMore}>
              <Button onClick={fetchMoreArticles} variant='link'>
                {isLoadingMoreArticles ? <Spinner animation='border' /> : 'Load More Articles'}
              </Button>
            </span>
          </div>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <div className={styles.rowContent}>
                <h5 className='title pb-2'>Commander of the Day</h5>
                <div className={styles.dailyCommander}>
                  <Card card={dailyCommander.daily} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <h5 className='title'>Top Commanders Weekly</h5>
              <BSCard className={classNames(styles.rowContent, 'shadow-sm')}>
                <BSCard.Body>
                  <ul>
                    {dailyCommander.weekly.map(({ name, url }, i) => (
                      <li key={i}>
                        {i + 1}. <CardHover left name={name} url={url} />
                      </li>
                    ))}
                  </ul>
                </BSCard.Body>
              </BSCard>
            </div>
          </div>
          {dailyCommander?.trending && (
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <h5 className='title'>Trending Cards</h5>
                <BSCard className={classNames(styles.rowContent, 'shadow-sm')}>
                  <BSCard.Body>
                    <ul>
                      {dailyCommander.trending.map(({ name, url }, i) => (
                        <li key={i}>
                          {i + 1}. <CardHover left name={name} url={url} />
                        </li>
                      ))}
                    </ul>
                  </BSCard.Body>
                </BSCard>
              </div>
            </div>
          )}
        </div>
      </div>
      {enableAdhesion && <div className='mvHomeAdhesion' />}
    </Main>
  )
}

export const getServerSideProps = async () => {
  try {
    const daily = await (await requestService.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/daily`)).json()
    const wordpress = await (
      await requestService.get(`${process.env.NEXT_PUBLIC_EDHREC_JSON_URL}/static/wordpress`)
    ).json()
    return {
      props: {
        enableAdhesion: true, // process.env.BUILD_TYPE === 'beta',
        initialDaily: daily,
        initialWordpress: wordpress,
      },
    }
  } catch (_error) {
    throw new Error('Failed to fetch data')
  }
}

export default HomePage
